
.mat-button-toggle {
  .mat-button-toggle-focus-overlay {
    left: 0;
    width: 100%;
    height: 0;
    right: 0;
    top: 0;
    bottom: auto;
    @include transition(0.2);
    opacity: 0.12;
  }
  &.mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      padding: 0 $spacing-s;
      @include bp-medium {
        padding: 0 $spacing-m;
      }
    }
    &.mat-button-toggle-checked {
      background: $primary;

      .mat-button-toggle-label-content {
        color: white;
      }
    }
  }
  &.mat-button-toggle-checked {
    .mat-button-toggle-focus-overlay {
      border-bottom: none;
    }
  }
  &:hover,
  &:focus {
    &:not(.mat-button-toggle-checked) .mat-button-toggle-focus-overlay {
      height: 100%;
    }
  }
}

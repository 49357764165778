@use '@angular/material' as mat;
@import "globalVars";

$my-app-primary: mat.define-palette($my-app-primary);
$my-app-accent: mat.define-palette($my-app-accent, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);
@include mat.core($custom-typography);

$primary: mat.get-color-from-palette($my-app-primary);
$primary-darken: map-get($my-app-primary, 900);
$accent: mat.get-color-from-palette($my-app-accent);
$accent-darken: map-get($my-app-accent, 900);
$danger: mat.get-color-from-palette($my-app-warn);

$link-color: $accent; // set link color based on the used material theme
$link-hover-color: currentColor;

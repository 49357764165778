
.info-box {
  border: $basic-border;
  border-radius: $basic-radius;
  padding: $spacing-r;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  fa-icon {
    margin-right: $spacing-xs;
    color: $accent;
  }
  p {
    margin: 0 0 $spacing-xs 0;
    &:last-child {
      margin: 0;
    }
  }
}
